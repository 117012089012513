/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAzXuhabccXAYAbVVr1EiP0FpBahp7ltLA",
  "appId": "1:206817671156:web:970eefdb1c6d5d66c26ba1",
  "authDomain": "schooldog-instance-walton.firebaseapp.com",
  "measurementId": "G-C4MHBHQQFB",
  "messagingSenderId": "206817671156",
  "project": "schooldog-instance-walton",
  "projectId": "schooldog-instance-walton",
  "showGaBranding": true,
  "storageBucket": "schooldog-instance-walton.appspot.com"
}
